import React, { useState } from "react"
import Layout from "../components/layout";
import Title from "../components/title";
import Accordion from "../components/accordion";
import { Helmet } from 'react-helmet';
import { Link } from "gatsby";

const Pricing = ({ data, location }) => {

  const [state, setState] = useState({
    currency: 'usd',
    billing: 'monthly'
  });

  const billing = {
    'usd': {
      'monthly': '$29',
      'yearly': '$290',
      'vat': false
    },
    'gbp': {
      'monthly': '£20',
      'yearly': '£200',
      'vat': true
    }
  }

  const toggleCurrency = () => {
    setState({
      ...state,
      currency: (state.currency === 'gbp' ? 'usd' : 'gbp')
    });
  };

  const toggleBilling = (type) => {
    setState({
      ...state,
      billing: type
    });
  };

  return (

    <Layout 
      path={location.pathname}>

    <Helmet
        bodyAttributes={{
          class: 'bg-gray-100 text-root'
        }} />

    <div className="container max-w-6xl mx-auto items-center justify-between my-24 lg:flex lg:space-x-24 xl:space-x-40">

      <Title
        title="Pricing"
        width="max-w-sm md:max-w-xl">

        <p className="max-w-xl leading-relaxed mx-auto mt-8 mb-12 text-lg md:text-xl"> 
        Costs less than 1 day of work per year. No credit card required and you can cancel anytime.
        </p>

        <Link to="https://app.heyslate.com/register" className="hidden lg:inline-block btn hover:bg-gray-400 hover:border-gray-400 hover:text-root">
          <span clasName="text-accent">Sign up for free</span>
        </Link>

      </Title>


      <div className="flex flex-col text-center bg-white p-8 lg:w-1/2 rounded-xl border border-gray-300">

        <div className="p-0 space-y-8">

          <div className="text-5xl flex flex-col space-y-4">{billing[state.currency][state.billing]}<small className="text-lg">{billing[state.currency].vat && '+VAT'} per month</small></div>

          <div class="flex items-center space-x-8">
            <hr class="w-full border-gray-300" />
            <span class="text-gray-500 uppercase">or</span>
            <hr class="w-full border-gray-300" />
          </div>

          <div className="text-5xl flex flex-col space-y-4">{billing[state.currency]['yearly']}<small className="text-lg"> per year</small></div>

        </div>

      </div>

      <div className="mt-16 text-center lg:hidden">

        <Link to="https://app.heyslate.com/register" className="inline-block btn hover:bg-gray-400 hover:border-gray-400 hover:text-root">
          <span clasName="text-accent">Sign up for free</span>
        </Link>

      </div>

    </div>

  </Layout>
)}

export default Pricing;
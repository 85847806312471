import React, { useState, useRef } from "react";
import Plus from "../../static/icons/plus.inline.svg";
import { RichText } from 'prismic-reactjs';

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  return (
    <div className="accordion__item">
      <button 
        className={`outline-none accordion md:text-lg font-medium ${setActive}`}
        onClick={toggleAccordion}>
        <span>{props.title[0].text}</span>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="text-sm sm:text-base accordion__content"
      >
        {RichText.render(props.content)}
      </div>
    </div>
  );
}

export default Accordion;